/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { ReducersNames } from '@store/constants';
import { DEFAULT_DATE_HOURS, DEFAULT_DATE_MINUTES } from '@app/modules/monitor-module/constants';

interface DailyReportState {
  dailyReportId: number;
  dailyReportDateTime: string;
  isEditable: boolean;
  // TODO: Temporary solution for new Roads. Remove and change related logic when excel Roads will use in filter Dictionary or similar
  excelRoads: Common.SelectorValues;
}

const initialState: DailyReportState = {
  dailyReportId: null,
  dailyReportDateTime: moment().set({ hours: DEFAULT_DATE_HOURS, minutes: DEFAULT_DATE_MINUTES }).format(),
  isEditable: false,
  excelRoads: [],
};

const dailyReportSlice = createSlice({
  name: ReducersNames.dailyReport,
  initialState,
  reducers: {
    setDailyReportId(state: DailyReportState, { payload }: PayloadAction<number>) {
      state.dailyReportId = payload;
    },
    setDailyReportDateTime(state: DailyReportState, { payload }: PayloadAction<string>) {
      state.dailyReportDateTime = payload;
    },
    setDailyReportIsEditable(state: DailyReportState, { payload }: PayloadAction<boolean>) {
      state.isEditable = payload;
    },
    setExcelRoads(state: DailyReportState, { payload }: PayloadAction<Common.SelectorValues>) {
      state.excelRoads = payload;
    },
  },
});

export const dailyReportActions = dailyReportSlice.actions;

export default dailyReportSlice.reducer;
