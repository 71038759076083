import { DataTestIds, MainPaths, ReportsGroups, ReportsSections, ReportType, UsersAccess } from '@app/v2/shared/enums';
import { allReports } from '@app/v2/shared/constants';
import { reportsToSidebarItems } from '@app/v2/shared/helpers';

const sidebar: StaticConfigs.Sidebar[] = [
  {
    key: 'monitoring',
    label: 'monitoring',
    children: [
      {
        key: 'map',
        label: 'map',
        perimeters: [UsersAccess.MAP_LOCATORS_ALL_LAYERS, UsersAccess.MAP_LOCATORS, UsersAccess.MAP_ROAD_OBJECTS, UsersAccess.PAGE_MAP],
        disabled: true,
        url: '/map',
      },
      {
        key: 'traffic',
        label: 'traffic',
        perimeters: [UsersAccess.BUTTON_TRAFFIC_REPORTS, UsersAccess.PAGE_TRAFFIC],
        disabled: true,
        children: [
          {
            key: 'trafficTable',
            label: 'table',
            url: '/traffic',
          },
          {
            key: 'traffic_reports',
            label: 'reports',
            perimeters: [UsersAccess.BUTTON_TRAFFIC_REPORTS],
            disabled: true,
            children: [
              ...reportsToSidebarItems(allReports[ReportsSections.Common][ReportType.Traffic].filter(({ group }) => group === ReportsGroups.Main)),
              {
                key: 'traffic_reports',
                label: 'additionalReports',
                perimeters: [UsersAccess.BUTTON_TRAFFIC_REPORTS],
                disabled: true,
                children: reportsToSidebarItems(
                  allReports[ReportsSections.Common][ReportType.Traffic].filter(({ group }) => group === ReportsGroups.Additional),
                ),
              },
            ],
          },
        ],
      },
      {
        key: 'meteo',
        label: 'meteo',
        perimeters: [
          UsersAccess.PAGE_METEO,
          UsersAccess.METEO_ROADCAST_AND_FORECAST,
          UsersAccess.BUTTON_METEO_REPORT,
          UsersAccess.BUTTON_METEO_LINEAR_PLAN_GRAPHS,
        ],
        disabled: true,
        children: [
          {
            key: 'meteoTable',
            label: 'table',
            url: '/meteo',
          },
          {
            key: 'meteo_reports',
            label: 'reports',
            perimeters: [UsersAccess.BUTTON_METEO_REPORT],
            disabled: true,
            children: reportsToSidebarItems(allReports[ReportsSections.Common][ReportType.Meteo]),
          },
          {
            key: 'notifications_archive',
            label: 'notificationsArchive',
            disabled: true,
            url: '',
          },
        ],
      },
      {
        key: 'video',
        label: 'video',
        perimeters: [
          UsersAccess.PAGE_VIDEO,
          UsersAccess.BUTTON_VIDEO_CLEAR,
          UsersAccess.BUTTON_VIDEO_LIVE,
          UsersAccess.PAGE_VIDEO_POPUP,
          UsersAccess.BUTTON_VIDEO_REPORT,
        ],
        disabled: true,
        url: '/video',
        children: [
          {
            key: 'videoTable',
            label: 'table',
            url: '/video',
          },
        ],
      },
      {
        key: 'map_notes',
        label: 'notes',
        url: '/map-notes',
        perimeters: [UsersAccess.PAGE_NOTES],
        disabled: true,
      },
    ],
  },
  {
    key: 'electronicJournals',
    label: 'electronicJournals',
    perimeters: [UsersAccess.BUTTON_JOURNALS_EDIT, UsersAccess.PAGE_JOURNALS],
    disabled: true,
    children: [
      {
        key: 'dtp_journal',
        label: 'dtpJournal',
        url: '/logs/accident',
      },
      {
        key: 'duty_journal',
        label: 'dutyJournal',
        url: '/logs/duty',
      },
      {
        key: 'traffic_break_journal',
        label: 'trafficBreakJournal',
        url: '/logs/breaks',
      },
      {
        key: 'traffic_restriction_journal',
        label: 'trafficRestrictionJournal',
        url: '/logs/traffic-restriction',
      },
      {
        key: 'es_journal_facility',
        label: 'esJournalFacility',
        url: '/logs/emergency-situation-facility',
      },
      {
        key: 'es_journal_building',
        label: 'esJournalBuilding',
        url: '/logs/emergency-situation-building',
      },
      {
        key: 'es_report',
        label: 'esReport',
        url: '/logs/emergency-situation',
      },
      {
        key: 'forecast_journal',
        label: 'forecastJournal',
        url: '/logs/hazard',
      },
      {
        key: 'attracting_forces_journal',
        label: 'attractingForcesJournal',
        url: '/logs/forces-attracting',
      },
      {
        key: 'flood_effects_help',
        label: 'floodEffectsHelp',
        url: '/logs/flood-effects',
      },
      {
        key: 'consolidated_report',
        label: 'consolidatedReport',
        disabled: true,
        url: '',
      },
    ],
  },
  {
    key: 'trafficControl',
    label: 'trafficControl',
    perimeters: [UsersAccess.VMS_FULL_EDIT, UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_VIEW_ONLY, UsersAccess.SERVICE_ENGINEER],
    disabled: true,
    children: [
      {
        key: 'configuration',
        label: 'configuration',
        url: '/scoreboard/configuration',
        perimeters: [UsersAccess.ROOT],
        disabled: true,
      },
      {
        key: 'devices',
        label: 'devices',
        url: '/scoreboard/devices',
        perimeters: [UsersAccess.SERVICE_ENGINEER],
        disabled: true,
      },
      {
        key: 'scoreboards',
        label: 'scoreboards',
        url: '/scoreboard/templates',
        perimeters: [UsersAccess.ROOT],
        disabled: true,
      },
      {
        key: 'signs',
        label: 'signs',
        url: '/scoreboard/signs',
        perimeters: [UsersAccess.ROOT],
        disabled: true,
      },
      {
        key: 'playlists',
        label: 'playlists',
        url: '/scoreboard/playlists',
        perimeters: [UsersAccess.ROOT],
        disabled: true,
      },
      {
        key: 'scoreboards_and_signs_v2',
        label: 'scoreboardsAndSignsV2',
        url: '/scoreboard/scoreboard_and_signs_v2',
        perimeters: [UsersAccess.VMS_VIEW_ONLY, UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT],
        disabled: true,
      },
    ],
  },
  {
    key: 'guides',
    label: 'guides',
    perimeters: [UsersAccess.EDIT_REFERENCES, UsersAccess.PAGE_REFERENCES],
    disabled: true,
    children: [
      {
        key: 'guide_organizations',
        label: 'guideOrganizations',
        url: '/guides/organizations',
      },
      {
        key: 'guide_users',
        label: 'guideUsers',
        url: '/guides/users',
      },
      {
        key: 'guide_roads',
        label: 'guideRoads',
        url: '/guides/roads',
      },
    ],
  },
  {
    key: 'services',
    label: 'services',
    // todo change after definition with roles
    perimeters: [UsersAccess.PAGE_SERVICES],
    disabled: true,
    dataTestId: DataTestIds.SidebarServices,
    children: [
      {
        key: 'meteo',
        label: 'meteo',
        dataTestId: DataTestIds.SidebarServiceMeteo,
        children: [
          {
            key: 'meteoManagement',
            label: 'management',
            url: '/services/meteoManagement',
            dataTestId: DataTestIds.SidebarServiceMeteoTable,
          },
          {
            key: 'service_meteo_reports',
            label: 'reports',
            perimeters: [UsersAccess.BUTTON_METEO_REPORT],
            disabled: true,
            dataTestId: DataTestIds.SidebarServiceMeteoReports,
            children: reportsToSidebarItems(allReports[ReportsSections.Services][ReportType.ServiceMeteo]),
          },
        ],
      },
      {
        key: 'video',
        label: 'video',
        dataTestId: DataTestIds.SidebarServiceVideo,
        children: [
          {
            key: 'videoManagement',
            label: 'management',
            url: '/services/videoManagement',
            dataTestId: DataTestIds.SidebarServiceVideoTable,
          },
          {
            key: 'service_video_reports',
            label: 'reports',
            perimeters: [UsersAccess.BUTTON_VIDEO_REPORT],
            disabled: true,
            dataTestId: DataTestIds.SidebarServiceVideoReports,
            children: reportsToSidebarItems(allReports[ReportsSections.Services][ReportType.ServiceVideo]),
          },
        ],
      },
      {
        key: 'traffic',
        label: 'traffic',
        dataTestId: DataTestIds.SidebarServiceTraffic,
        children: [
          {
            key: 'trafficManagement',
            label: 'management',
            url: '/services/trafficManagement',
            dataTestId: DataTestIds.SidebarServiceTrafficTable,
          },
          {
            key: 'service_traffic_reports',
            label: 'reports',
            perimeters: [UsersAccess.BUTTON_TRAFFIC_REPORTS],
            disabled: true,
            dataTestId: DataTestIds.SidebarServiceTrafficReports,
            children: reportsToSidebarItems(allReports[ReportsSections.Services][ReportType.ServiceTraffic]),
          },
        ],
      },
    ],
  },
  {
    key: 'specGidroMed',
    label: 'specGidroMed',
    perimeters: [UsersAccess.SPECGIDROMET, UsersAccess.ROOT],
    disabled: true,
    children: [
      {
        key: 'cityWeatherForecast',
        label: 'cityWeatherForecast',
        perimeters: [UsersAccess.SPECGIDROMET, UsersAccess.ROOT],
        url: '/specGidroMed/cityWeatherForecast',
        disabled: true,
      },
    ],
  },
  {
    key: 'adaptationToClimateChange',
    label: 'adaptationToClimateChange',
    perimeters: [UsersAccess.ROOT, UsersAccess.SUPERVISOR, UsersAccess.PAGE_DPGT],
    disabled: true,
    url: '/adaptation-to-climate-change',
  },
  {
    key: 'SOPS',
    label: 'SOPS',
    perimeters: [UsersAccess.ROOT, UsersAccess.PAGE_SOPS, UsersAccess.PAGE_SAPO],
    disabled: true,
    url: '/SOPS',
  },
  {
    key: 'locatorAnalysis',
    label: 'locatorAnalysis',
    perimeters: [UsersAccess.PAGE_LOCATOR_ANALYSIS],
    disabled: true,
    dataTestId: DataTestIds.SidebarLocatorAnalyze,
    children: [
      {
        key: 'locatorTable',
        label: 'table',
        url: '/locator-analysis',
        dataTestId: DataTestIds.SidebarLocatorAnalyzeTable,
      },
      {
        key: 'locator_reports',
        label: 'reports',
        perimeters: [UsersAccess.PAGE_LOCATOR_ANALYSIS],
        disabled: true,
        dataTestId: DataTestIds.SidebarLocatorAnalyzeReports,
        children: reportsToSidebarItems(allReports[ReportsSections.Common][ReportType.Locator]),
      },
    ],
  },
  {
    key: 'ecoMonitoring',
    label: 'ecoMonitoring',
    disabled: true,
    perimeters: [UsersAccess.PAGE_ECO_MONITORING],
    url: '/eco-monitoring',
  },
  {
    key: 'stationDetails',
    label: 'stationDetails',
    disabled: true,
    perimeters: [UsersAccess.PAGE_DPD, UsersAccess.ROOT],
    url: `/station-details`,
  },
  {
    key: 'videoWaterLevelControl',
    label: 'videoWaterLevelControl',
    disabled: true,
    perimeters: [UsersAccess.ROOT, UsersAccess.SUPERVISOR, UsersAccess.PAGE_SCLR],
    url: '/video-water-level-control',
  },
  {
    key: 'incidents',
    label: 'incidents',
    disabled: true,
    perimeters: [UsersAccess.ROOT, UsersAccess.VIDEO_ACCIDENTS],
    url: '/incidents',
  },
  {
    key: 'federalRoads',
    label: 'federalRoads',
    perimeters: [UsersAccess.EXCEL_ROAD_USER, UsersAccess.EXCEL_ROAD_MODERATOR],
    disabled: true,
    url: MainPaths.FederalRoads,
  },
  {
    key: 'roadManagementOrgan',
    label: 'roadManagementOrgan',
    disabled: true,
    perimeters: [UsersAccess.EXCEL_ROAD_USER, UsersAccess.EXCEL_ROAD_MODERATOR],
    url: MainPaths.RoadManagementOrgan,
  },
  {
    key: 'monitorPage',
    label: 'monitorPage',
    disabled: true,
    perimeters: [UsersAccess.JOURNAL_USER, UsersAccess.JOURNAL_MODERATOR],
    url: MainPaths.Monitor,
  },
];

export default sidebar;
