import React, { ReactNode, useEffect, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import useUpdateBuildWorker from '@app/core/hooks/useUpdateBuildWorker';
import KitModule from '@app/modules/kit-module';
import { useAccount, useAuth, useConsiderUTC, useFilterMonitor } from '@app/v2/shared/hooks';
import { useLanguageChange } from '@app/v2/shared/localization';
import themeCreator from '@theme/index';
// import { CSDChat } from '@app/v2/shared/ui/custom';
import { Authorization, Main, useAppType } from '@app/v2/app';
import { AppTypes } from '@app/v2/shared/enums';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import DateWorker from '@app/workers/date.worker';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CountdownWorker from '@app/workers/countdown.worker';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import UpdateBuildWorker from '@app/workers/updateBuild.worker';

const App = () => {
  useEffect(() => {
    const loader = document.querySelector('.loader');

    loader.classList.remove('loader');

    return () => {
      loader.classList.add('loader');
    };
  }, []);

  const currentAppType = useAppType();
  useLanguageChange();
  useConsiderUTC();
  useFilterMonitor();

  const { isAuth } = useAuth();
  const { fetchSettings } = useAccount();

  useEffect(() => {
    if (!isAuth) return;

    fetchSettings();
  }, [fetchSettings, isAuth]);

  const { startWorker, stopWorker } = useUpdateBuildWorker();

  useEffect(() => {
    startWorker();

    return () => {
      stopWorker();
    };
  }, [startWorker, stopWorker]);

  const app = useMemo<Record<AppTypes, ReactNode>>(
    () => ({
      [AppTypes.KIT]: <KitModule />,
      [AppTypes.MAIN]: <Main />,
      [AppTypes.AUTHORIZATION]: <Authorization />,
    }),
    [],
  );

  return (
    <ThemeProvider theme={themeCreator()}>
      <CssBaseline />

      {app[currentAppType]}

      {/* BROKE MAP (using the same function name like leaflet in global object, so it override leaflet) */}
      {/* <CSDChat /> */}
    </ThemeProvider>
  );
};

export default App;
