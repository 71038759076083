import * as React from 'react';
import { useCallback, useMemo } from 'react';
import ListingPage from '@app/components/listing-page/ListingPage';
import useDialog from '@app/core/hooks/useDialog';
import ListingAddNoteDialog from '@app/modules/map-module/components/dialogs/ListingAddNoteDialog';
import { MapNoteInput } from '@app/modules/map-module/components/map/notes/types';
import { DialogData } from '@app/components/common/dialog/types/DialogData';
import ListingNotesCards from '@app/components/notes/ListingNotesCards';
import useGenerateHeaders from '@app/components/notes/hooks/useGenerateHeaders';
import NotesCarousel from '@app/components/notes/NotesCarousel';
import { HORIZONTAL_LISTING_NOTES_HEIGHT } from '@app/v2/shared/constants';
import CSDCreateNoteButton from '@app/components/notes/CSDCreateNoteButton';
import CSDEmptyListingByFiltersListing from '@app/v2/shared/ui/layout/emptyListing/CSDEmptyListingByFiltersListing';
import CSDLoadingListing from '@app/v2/shared/ui/layout/loadingListing/CSDLoadingListing';
import { useActions, useAppSelector } from '@app/v2/shared/hooks';
import MapNotesDataSource from '../../data/MapNotesDataSource';

const MapNotesListing = ({ isHorizontal = false }: { isHorizontal?: boolean }) => {
  const dataSource = useMemo(() => new MapNotesDataSource(), []);
  const refetchNotes = useCallback(() => {
    dataSource.load();
  }, [dataSource]);
  const headers = useGenerateHeaders({ refetchNotes });
  const mapDialog = useDialog<{ dialogData: DialogData<MapNoteInput> }>(ListingAddNoteDialog);

  const { setIsReloadNoteListing } = useActions();
  const isReloadNoteListing = useAppSelector(state => state.map.isReloadNoteListing);

  const reloadData = useCallback(() => {
    dataSource.load();
  }, [dataSource]);

  if (isReloadNoteListing) {
    reloadData();
    setIsReloadNoteListing(false);
  }

  return (
    <ListingPage
      style={isHorizontal ? { height: HORIZONTAL_LISTING_NOTES_HEIGHT } : undefined}
      dataSource={dataSource}
      settingsPanel={!isHorizontal ? () => <CSDCreateNoteButton handleAdd={mapDialog} /> : undefined}
      dataTable={({ handleVirtualizedScroll, listingData, loading }) => {
        listingData.sort((firstNote, nextNote) => new Date(nextNote.dateTime).getTime() - new Date(firstNote.dateTime).getTime());

        return (
          <>
            {loading && !listingData.length && <CSDLoadingListing />}
            {!loading && !listingData.length && <CSDEmptyListingByFiltersListing />}

            {!loading &&
              !!listingData.length &&
              (isHorizontal ? (
                <NotesCarousel handleVirtualizedScroll={handleVirtualizedScroll} listingData={listingData} headers={headers} loading={loading} />
              ) : (
                <ListingNotesCards handleVirtualizedScroll={handleVirtualizedScroll} listingData={listingData} headers={headers} />
              ))}
          </>
        );
      }}
    />
  );
};

export default MapNotesListing;
