import React from 'react';
import { Button, DialogActions, Icon } from '@mui/material';
import icons from '@app/assets/iconFont';

interface Props {
  disabled?: boolean;
  doneHandler: Common.VoidCallBack;
  closeHandler: Common.VoidCallBack;
}

const CSDDialogActions = ({ disabled, doneHandler, closeHandler }: Props) => {
  return (
    <DialogActions sx={{ justifyContent: 'flex-start', gap: '1rem' }}>
      <Button onClick={doneHandler} disabled={disabled} data-testid="dialog-actions-btn-done">
        <Icon>{icons.done}</Icon>
      </Button>
      <Button variant="error" onClick={closeHandler} disabled={disabled} data-testid="dialog-actions-btn-close">
        <Icon>{icons.close}</Icon>
      </Button>
    </DialogActions>
  );
};

export default CSDDialogActions;
