enum RoadAccident {
  Y = 'ДТП_У',
  R = 'ДТП_Р',
  DY = 'ДТП_ДУ',
  O = 'ДТП_О',
  J = 'ДТП_Ж',
  SH = 'ДТП_Ш',
  V = 'ДТП_В',
  P = 'ДТП_П',
}

export default RoadAccident;
