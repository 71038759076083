import React, { useContext, ReactNode } from 'react';
import clsx from 'clsx';
import { TableCell, TableCellProps, Tooltip, TooltipProps } from '@mui/material';
import { TableHighlightContext, UNHIGHLIGHTED_COLUMN_INDEX, UNHIGHLIGHTED_ROW_INDEX } from '../CSDTable/CSDTable';

export interface HighlightedCell {
  columnIndex: number;
  rowIndex: number;
}

interface Props extends HighlightedCell, TableCellProps {
  label?: ReactNode;
  className?: string;
  children?: React.ReactElement | string;
  tooltipProps?: Partial<TooltipProps>;
  isNormalLineHeight?: boolean;
}

const CSDTableCell = (props: Props) => {
  const { label, children, className, tooltipProps, rowIndex, columnIndex, isNormalLineHeight = false, ...cellProps } = props;
  const tableContext = useContext(TableHighlightContext);

  const onMouseEnter = event => {
    if (event.currentTarget.closest('thead')) return;

    tableContext.setHighlightedCell({
      column: columnIndex,
      row: rowIndex,
    });
  };

  const onMouseLeave = () => {
    tableContext.setHighlightedCell({
      column: UNHIGHLIGHTED_COLUMN_INDEX,
      row: UNHIGHLIGHTED_ROW_INDEX,
    });
  };

  // All cells in row before hovered element, and all cells in column higher that hovered element
  const isCellInScopeOfHighlighting =
    (tableContext.row === rowIndex && tableContext.column >= columnIndex) || (tableContext.column === columnIndex && tableContext.row >= rowIndex);

  const getTableCell = () => {
    return (
      <TableCell
        align="center"
        className={clsx(className, { hightlight: isCellInScopeOfHighlighting })}
        style={{ lineHeight: isNormalLineHeight ? 'normal' : 0 }}
        {...cellProps}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </TableCell>
    );
  };

  return label ? (
    <Tooltip title={label} arrow {...tooltipProps}>
      {getTableCell()}
    </Tooltip>
  ) : (
    getTableCell()
  );
};

export default CSDTableCell;
