import RoadStatusConstants from '@app/modules/federal-roads-module/utils/constants';
import { AccountSettings } from '@store/slices/account/types';

export function findCurrentVersion(versions: FederalRoads.Version[]): FederalRoads.Version {
  if (!versions?.length) {
    return null;
  }

  return versions.find(version => version.isCurrent) || versions[0];
}

export function isShowEditHeader(isModerator: boolean, versions: FederalRoads.Version[]): boolean {
  return isModerator && !!versions?.find(version => version.status === RoadStatusConstants.NEW_ROAD_STATUS);
}

export function isShowEditCell(isModerator: boolean, status: string): boolean {
  return isModerator && status === RoadStatusConstants.NEW_ROAD_STATUS;
}

export function isUserCanEditCurrentVersion(isModerator: boolean, version: FederalRoads.Version, settings: AccountSettings): boolean {
  if (!version) {
    return false;
  }

  return isModerator || version.author.organization.id === settings.userOrganizationId;
}
