export enum JournalReportWidgetTypes {
  TrafficAccidents = 'trafficAccidents',
  RestrictionReports = 'restrictionReports',
  InterruptionReports = 'interruptionReports',
  EmergencyReports = 'emergencyReports',
  NewAccidentReports = 'newAccidentReports',
}

export enum ReportStatuses {
  Draft = 'draft',
  Prepared = 'prepared',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum RestrictionReportTypes {
  Interruption = 'interruption',
  Restriction = 'restriction',
}

export enum AccidentFormTypes {
  TrafficAccidentReport = 'trafficAccidentFistStep',
  RestrictionReport = 'restrictionReport',
  EmergencyReport = 'emergencyReport',
  NewAccidentReport = 'newAccidentReport',
}

export enum AccidentFormSteps {
  TrafficAccidentFirstStep = 'trafficAccidentFistStep',
  RestrictionReport = 'restrictionReport',
  EmergencyReport = 'emergencyReport',
  NewAccidentReport = 'newAccidentReport',
  TrafficAccidentSecondStep = 'trafficAccidentSecondStep',
}

export enum RestrictionTypes {
  Start = 'Н',
  End = 'К',
}

export enum JournalDnDSettingsTypes {
  Position = 'position',
  Size = 'size',
}

// Reports keys

export enum TrafficAccidentKeys {
  Id = 'id',
  City = 'city',
  Code = 'code',
  Conditions = 'conditions',
  Description = 'description',
  RoadId = 'roadId',
  AccidentDt = 'accidentDt',
  RoadPartKm = 'roadPartKm',
  RoadPartM = 'roadPartM',
  Restriction = 'restriction',
  AdditionalInfo = 'additionalInfo',
  Video = 'video',
  Photo = 'photo',

  Injured = 'injured',
  InjuredChildren = 'injuredChildren',
  Died = 'died',
  DiedChildren = 'diedChildren',
  Claims = 'claims',
  Damage = 'damage',
  Measures = 'measures',
}

export enum LocationIntervalKeys {
  RoadId = 'roadId',
  AccidentDt = 'accidentDt',
  City = 'city',
  StartRoadPartKm = 'startRoadPartKm',
  StartRoadPartM = 'startRoadPartM',
  EndRoadPartKm = 'endRoadPartKm',
  EndRoadPartM = 'endRoadPartM',
}

export enum RestrictionReportKeys {
  Id = 'id',
  ParentId = 'parentId',
  Type = 'type',
  Reason = 'reason',
  Description = 'description',
  StartDt = 'startDt',
  EndDt = 'endDt',
}

export enum EmergencyReportKeys {
  Id = 'id',
  Criteria = 'criteria',
  Description = 'description',
  AdditionalDescription = 'additionalDescription',
  Measures = 'measures',
}

export enum NewAccidentReportKeys {
  Id = 'id',
  Description = 'description',
  Damage = 'damage',
  Measures = 'measures',
}

export enum MeasuresAccidentKeys {
  Emergency = 'emergency',
  Government = 'government',
  RoadInspection = 'roadInspection',
  Video = 'video',
  Photo = 'photo',
  Channels = 'channels',
}

export enum DialogDataKeys {
  TrafficAccidentData = 'trafficAccidentData',
  RestrictionReportData = 'restrictionReportData',
  EmergencyReportData = 'emergencyReportData',
  NewAccidentReportData = 'newAccidentReportData',
}
