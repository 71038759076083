import { JournalReportWidgetTypes } from '@app/v2/shared/enums';

export const DEFAULT_JOURNAL_WIDGET_SETTINGS = {
  [JournalReportWidgetTypes.TrafficAccidents]: {
    order: 0,
    size: 2,
  },
  [JournalReportWidgetTypes.RestrictionReports]: {
    order: 1,
    size: 1,
  },
  [JournalReportWidgetTypes.InterruptionReports]: {
    order: 2,
    size: 1,
  },
  [JournalReportWidgetTypes.EmergencyReports]: {
    order: 3,
    size: 2,
  },
  [JournalReportWidgetTypes.NewAccidentReports]: {
    order: 4,
    size: 2,
  },
};

export const GRID_COLUMNS = 4;

export const REPORT_DAY_SHIFT = 1;

export const DEFAULT_MIN_COLUMN_SIZE = 1;
export const DEFAULT_TRAFFIC_ACCIDENT_MIN_COLUMN_SIZE = 2;

export const DEFAULT_DATE_HOURS = 8;
export const DEFAULT_DATE_MINUTES = 0;

export const MAX_KM_M_INPUT_LENGTH = 4;

export const ONLY_DIGITS_REGEX = /[^0-9]/g;

const VIDEO_POSTFIX = 'video/';
const IMAGE_POSTFIX = 'photo/';

export const IMAGE_UPLOAD_URL = process.env.REACT_APP_ACCIDENT_REPORT_FILES_UPLOAD_URL + IMAGE_POSTFIX;

export const VIDEO_UPLOAD_URL = process.env.REACT_APP_ACCIDENT_REPORT_FILES_UPLOAD_URL + VIDEO_POSTFIX;
